import { Injectable } from '@angular/core';
import { Amplify, Auth } from 'aws-amplify';
import { Subject } from 'rxjs';

import {
  AmplifyUser,
  SignInUserSession,
} from '../models/aws-amplify.interface';
import { Config } from '../models/config.interface';

import { UserProfile } from './models/user-profile.model';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private readonly signOutSubject = new Subject<boolean>();
  private config: Config = {} as Config;
  public signOut$ = this.signOutSubject.asObservable();

  constructor() {}

  public async init(config: Config): Promise<UserProfile | null> {
    this.config = config;
    this.configureAmplify();

    // Coming from login
    const isComingFromLogin =
      window.location.pathname.endsWith('/login') &&
      window.location.search.includes('?code=');
    if (isComingFromLogin) {
      return await this.getCurrentUser();
    }

    // Coming from logout
    const isComingFromLogout = window.location.pathname.endsWith('/logout');
    if (isComingFromLogout) {
      window.location.href = `${this.config.idp.issuerUrl}/idp/startSLO.ping`;
      return null;
    }

    // Check user state
    try {
      const userProfile = await this.getCurrentUser();
      // If logged in cognito then return user
      if (userProfile) {
        return userProfile;
      } else {
        this.signIn();
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  public async getCurrentUser(): Promise<UserProfile | null> {
    try {
      const amplifyUser = await Auth.currentAuthenticatedUser();
      if (amplifyUser !== null) {
        return this.formatUserProfile(amplifyUser);
      }
    } catch {
      // Not authenticated eslint-disable-line no-empty
    }
    return null;
  }

  public async signIn(): Promise<void> {
    const idps = this.config.idp.supportedIdps?.split(',');
    const idp = idps?.length == 1 ? idps[0] : '';
    const loginOptions = {
      customProvider: idp,
    };
    await Auth.federatedSignIn(loginOptions);
  }

  public async signOut(): Promise<void> {
    await Auth.signOut();
  }

  private configureAmplify(): void {
    const oauth = {
      clientId: this.config.idp.clientId,
      domain: this.config.idp.userPoolDomain,
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn: `${window.location.origin}/login`,
      redirectSignOut: `${window.location.origin}/logout`,
      responseType: 'code',
    };

    const amplifyConfig = {
      aws_project_region: 'eu-central-1',
      aws_cognito_region: 'eu-central-1',
      aws_user_pools_id: this.config.idp.userPoolId,
      aws_user_pools_web_client_id: this.config.idp.clientId,
      oauth,
    };

    Amplify.configure(amplifyConfig);
  }

  private formatUserProfile(amplifyUser: AmplifyUser): UserProfile {
    const signInUser: SignInUserSession = amplifyUser?.signInUserSession;
    const signInUserPayload = signInUser?.idToken?.payload;
    const name = this.extracNameFromIdp(amplifyUser);

    return {
      sub: signInUserPayload?.sub,
      name: name,
      givenName: signInUserPayload?.given_name,
      familyName: signInUserPayload?.family_name,
      email: signInUserPayload?.email,
      jwtId: signInUser?.idToken?.jwtToken,
      jwtAccess: signInUser?.accessToken?.jwtToken,
    } as UserProfile;
  }

  private extracNameFromIdp(amplifyUser: AmplifyUser): string {
    try {
      let name = amplifyUser?.attributes?.name;
      if (!name) {
        // For Roche IDP use User ID as name
        name = JSON.parse(amplifyUser.attributes.identities)[0].userId;
      }
      return name;
    } catch {} //eslint-disable-line no-empty
    return '';
  }
}
