import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { OneWebComponentsAngularModule } from '@one/angular';

@Component({
  selector: 'dl-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [OneWebComponentsAngularModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  public constructor(private sanitizer: DomSanitizer) {}

  public sanitizeURL(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
