<owc-footer>
  <div class="footer" slot="bottom">
    <owc-divider direction="horizontal"></owc-divider>
    <div class="bottomContent">
      <owc-typography variant="subtitle2">
        This information is proprietary and is intended as a reference and for
        internal use only. Do not share with external audiences.
      </owc-typography>

      <owc-typography variant="subtitle2">
        © 2021 F. Hoffmann-La Roche Ltd
      </owc-typography>
      <owc-typography variant="caption">
        Last updated: 08.03.2021
      </owc-typography>
    </div>
  </div>
</owc-footer>
