import { Injectable } from '@angular/core';

import { Config } from '../models/config.interface';

import { AuthApiService } from './auth-api.service';
import { UserProfile } from './models/user-profile.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public constructor(private authApiService: AuthApiService) {}

  public init(config: Config): Promise<UserProfile | null> {
    return this.authApiService.init(config);
  }

  public signIn(): void {
    this.authApiService.signIn();
  }

  public signOut(): Promise<void> {
    return this.authApiService.signOut();
  }

  public async getCurrentUser(): Promise<UserProfile | null> {
    return await this.authApiService.getCurrentUser();
  }
}
