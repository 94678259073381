import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'dl-app-enabler-first',
  templateUrl: './app-enabler-first.component.html',
  styleUrls: ['./app-enabler-first.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppEnablerFirstComponent {
  constructor() {}
}
