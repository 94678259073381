import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { OneWebComponentsAngularModule } from '@one/angular';

import { AuthService } from '../../../../core/auth/auth.service';
import { UserProfile } from '../../../../core/auth/models/user-profile.model';

@Component({
  selector: 'dl-double-global-area',
  templateUrl: './double-global-area.component.html',
  styleUrls: ['./double-global-area.component.scss'],
  standalone: true,
  imports: [OneWebComponentsAngularModule, NgIf],
})
export class DoubleGlobalAreaComponent implements OnInit {
  public profileData: UserProfile | null = null;
  public userInitials: string = '';

  private _selectedTab1 = 'menu';

  constructor(private authService: AuthService) {}

  public ngOnInit(): void {
    this.setUserProfileData();
  }

  set selectedTab1(tab: string) {
    this._selectedTab1 = tab;
  }

  get selectedTab1(): string {
    return this._selectedTab1;
  }

  public logout(): void {
    this.authService.signOut();
  }

  private setUserProfileData(): void {
    this.authService
      .getCurrentUser()
      .then((userProfile: UserProfile | null) => {
        if (userProfile === null) {
          this.profileData = null;
          // TODO in future it should be blocked. User should not see the interface if he is not logged in/authorized/his User Profile is not available
        } else {
          this.profileData = userProfile;
          this.userInitials =
            userProfile?.name?.substring(0, 1).toUpperCase() || '';
        }
      });
  }
}
