import { EnvironmentInterface } from '../app/core/models/environment.interface';

export const environment: EnvironmentInterface = {
  production: true,
  apiUrl: '',
  apiMulesoftSuffix: '/api/v1',
  appUrl:  '',
  appApiKey: "JO7shhIkpz35Ct28pcd0F7QhNjZOUZWh8xwgbwfs",
  appApiUrl: '',
  providers: []
};
