import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';

import { AuthService } from '../auth/auth.service';
import { EnvironmentInterface } from '../models/environment.interface';
import { ENVIRONMENT } from '../models/environment.token';
import { EnvConfigService } from '../services/env-config.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  public constructor(
    private authService: AuthService,
    @Inject(ENVIRONMENT) private environment: EnvironmentInterface,
    private envConfigService: EnvConfigService,
  ) {}

  public async initApp(): Promise<void> {
    this.determineAppUrls();
    await this.envConfigService.Init();
    await this.authService.init(this.envConfigService.config);
  }

  private determineAppUrls(): void {
    const appUrl = window.location.origin;
    this.environment.appUrl = appUrl;
    this.environment.appApiUrl = `${appUrl}/api/`;
    this.environment.apiUrl = `${appUrl}/sandbox`;
  }
}

export const appInitializerProvider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory:
    (appInitializerService: AppInitializerService): (() => Promise<void>) =>
    () =>
      appInitializerService.initApp(),
  deps: [AppInitializerService],
};
