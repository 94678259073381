import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { Config } from '../models/config.interface';

@Injectable({
  providedIn: 'root',
})
export class EnvConfigService {
  public config: Config = {} as Config;
  public header = {};

  constructor(
    public http: HttpClient,
    public router: Router,
  ) {}

  public async Init(): Promise<void> {
    const url = window.location.origin + '/api/v1/envconfig';
    this.config = await this.getConfig(url);
  }

  public getConfig(url: string): Promise<Config> {
    return firstValueFrom(this.http.get<Config>(url, this.header));
  }
}
